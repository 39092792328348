import { SupportedLocaleMap } from '@zep/consts/localize';

import { BannerType } from './StickyBanner';

export const BANNER_DATA: SupportedLocaleMap<
  'ko' | 'en' | 'ja' | 'id' | 'th',
  Partial<
    Record<
      BannerType,
      { imgSrc: string; linkHref: string; loginRequired: boolean }
    >
  >
> = {
  ko: {
    '2_WEEKS_FREE_TRIAL': {
      imgSrc: '/assets/sticky_banner_5_ko.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ko/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
  },
  en: {
    EARLY_ACCESS: {
      imgSrc: '/assets/early_access_banner_en.webp',
      linkHref: 'https://tally.so/r/nGqj2z',
      loginRequired: false,
    },
  },
  ja: {
    '2_WEEKS_FREE_TRIAL': {
      imgSrc: '/assets/sticky_banner_5_ja.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ja/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
  },
  id: {
    EARLY_ACCESS: {
      imgSrc: '/assets/early_access_banner_id.webp',
      linkHref: 'https://tally.so/r/wo5jgX',
      loginRequired: false,
    },
  },
  th: {
    EARLY_ACCESS: {
      imgSrc: '/assets/early_access_banner_th.webp',
      linkHref: 'https://tally.so/r/nPbbNb',
      loginRequired: false,
    },
  },
};

export const BANNER_CLASSNAMES: Record<BannerType, string> = {
  '2_WEEKS_FREE_TRIAL': 'bg-gradient-to-l from-[#AF70FF] to-[#3DA9FF]',
  EARLY_ACCESS: 'bg-[#7EC753]',
};
