import { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useEditQuizUserName } from '@zep/api';
import { setStorageUser } from '@zep/lib/auth';
import { zepAnalytics } from '@zep/utils/analytics';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogPanel, DialogTitle } from '../ui/dialog';
import { TextField } from '../ui/TextField';
type NameEditModalProps = {
  initialName?: string;
};
const _NameEditModal = ({
  initialName
}: NameEditModalProps) => {
  const modal = useModal();
  const router = useRouter();
  const [nameInputValue, setNameInputValue] = useState('');
  const {
    t
  } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(e.target.value);
  };
  const {
    mutate,
    isPending
  } = useEditQuizUserName({
    mutation: {
      onSuccess: (_, variables) => {
        setStorageUser({
          username: variables.data.name
        });
        router.reload();
        zepAnalytics.track('edit_name');
        modal.remove();
      },
      onError: () => {
        alert(t('nameChangeModal.error'));
      }
    }
  });
  useEffect(() => {
    setNameInputValue(initialName ?? '');
  }, [initialName]);
  const handleSubmit = () => {
    if (!nameInputValue.trim().length) {
      return;
    }
    mutate({
      data: {
        name: nameInputValue.trim().slice(0, 14)
      }
    });
  };
  return <Dialog onClick={modal.remove} data-sentry-element="Dialog" data-sentry-component="_NameEditModal" data-sentry-source-file="NameEditModal.tsx">
      <DialogPanel className="max-w-[720px]" data-sentry-element="DialogPanel" data-sentry-source-file="NameEditModal.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="NameEditModal.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="NameEditModal.tsx">{t('nameChangeModal.title')}</DialogTitle>
        </DialogHeader>
        <DialogContent className="py-0" data-sentry-element="DialogContent" data-sentry-source-file="NameEditModal.tsx">
          <TextField className="w-full" autoFocus maxLength={14} type="text" value={nameInputValue} onChange={handleChange} data-sentry-element="TextField" data-sentry-source-file="NameEditModal.tsx" />
        </DialogContent>
        <DialogFooter className="flex-row-reverse" data-sentry-element="DialogFooter" data-sentry-source-file="NameEditModal.tsx">
          <button className="rounded bg-primary px-md py-[13px] font-bold text-white" disabled={isPending} type="submit" onClick={handleSubmit}>
            {isPending ? t('nameChangeModal.changing') : t('global.confirm.button')}
          </button>
          <button className="rounded bg-gray-100 px-md py-[13px] font-bold text-gray-600" onClick={modal.remove}>
            {t('global.cancel.button')}
          </button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>;
};
export const NameEditModal = NiceModal.create(_NameEditModal);