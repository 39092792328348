import { useRef } from 'react';
import { isSafari } from 'react-device-detect';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useInputState } from '@toss/react';
import { useGetQuizSetByEnterCode } from '@zep/api';
import { useAlert } from '@zep/hooks';
import { zepAnalytics } from '@zep/utils/analytics';
import { useTranslation } from 'next-i18next';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogPanel, DialogTitle } from '../ui/dialog';
import { TextField } from '../ui/TextField';
function _CodeEnter() {
  const modal = useModal();
  const {
    t
  } = useTranslation();
  const [inputValue, setInputValue] = useInputState('');
  const {
    mutateAsync,
    isPending
  } = useGetQuizSetByEnterCode();
  const alert = useAlert();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!inputValue?.trim()) {
      alert({
        type: 'alert',
        title: t('codeEnterModal.error.input')
      }, () => {
        inputRef?.current?.focus();
      });
      return;
    }
    try {
      const {
        data
      } = await mutateAsync({
        data: {
          enterCode: Number(inputValue)
        }
      });
      zepAnalytics.track('code_complete');
      zepAnalytics.track('enter_play', {
        location: 'enter_code',
        spaceId: data.spaceHashId
      });
      if (isSafari) {
        location.href = `/play/${data.entryMapHashId}`;
      } else {
        window.open(`/play/${data.entryMapHashId}`, '_blank');
      }
    } catch (e) {
      alert({
        type: 'alert',
        title: t('codeEnterModal.error.notFound')
      }, () => {
        inputRef?.current?.focus();
      });
      console.error(e);
    }
  };
  return <Dialog onClick={modal.remove} data-sentry-element="Dialog" data-sentry-component="_CodeEnter" data-sentry-source-file="CodeEnter.tsx">
      <DialogPanel className="max-w-[720px]" data-sentry-element="DialogPanel" data-sentry-source-file="CodeEnter.tsx">
        <DialogHeader onClose={modal.remove} data-sentry-element="DialogHeader" data-sentry-source-file="CodeEnter.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CodeEnter.tsx">{t('codeEnterModal.title')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="CodeEnter.tsx">
            <TextField isBig autoFocus ref={inputRef} maxLength={10} placeholder={t('codeEnterModal.placeholder')} value={inputValue} disabled={isPending} onChange={setInputValue} type="number" className="w-full [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none" data-sentry-element="TextField" data-sentry-source-file="CodeEnter.tsx" />
          </DialogContent>
          <DialogFooter className="flex justify-end pt-0" data-sentry-element="DialogFooter" data-sentry-source-file="CodeEnter.tsx">
            <button disabled={isPending} type="submit" className="min-w-[140px] rounded-[6px] bg-primary px-sm py-xs text-body-lg font-semibold leading-[1.4] text-white">
              {t('global.enter.button')}
            </button>
          </DialogFooter>
        </form>
      </DialogPanel>
    </Dialog>;
}
export const CodeEnter = NiceModal.create(_CodeEnter);