import { Fragment } from 'react';
import { DialogPanel as _DialogPanel, DialogPanelProps, TransitionChild } from '@headlessui/react';
import cns from 'classnames';
import S from './DialogPanel.module.scss';
export const DialogPanel = (props: DialogPanelProps<'div'>) => {
  const {
    className,
    children
  } = props;
  return <TransitionChild as={Fragment} enter={S.enter} enterFrom={S.enter_from} enterTo={S.enter_to} leave={S.leave} leaveFrom={S.leave_from} leaveTo={S.leave_to} data-sentry-element="TransitionChild" data-sentry-component="DialogPanel" data-sentry-source-file="DialogPanel.tsx">
      <_DialogPanel {...props} className={cns(S.panel, (className as string))} tabIndex={0} data-sentry-element="_DialogPanel" data-sentry-source-file="DialogPanel.tsx">
        {children}
      </_DialogPanel>
    </TransitionChild>;
};