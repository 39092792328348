/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  CreateQuizSetReq,
  DeleteQuizSetReq,
  DeprecatedGetQuizSetDetailsParams,
  DeprecatedUpdatePlayCountParams,
  GenerateQuizzesByAIReq,
  GetQuizSetBuildInfoParams,
  GetQuizSetByEntryMapParams,
  GetQuizSetDetailsParams,
  QuizSetEnterReq,
  SearchQuizSetReq,
  SearchResponseListSearchQuizResV1,
  SuccessBodyListQuestionDto,
  SuccessBodyObject,
  SuccessBodyQuizAuthorRes,
  SuccessBodyQuizDetailsRes,
  SuccessBodyQuizEntryMapRes,
  SuccessBodyQuizSetBuildInfoRes,
  SuccessBodyQuizSetDetailsRes,
  SuccessBodyUploadImageRes,
  UpdatePlayCountParams,
  UpdateQuizSetReq,
  UploadImageBody,
  VerifyAuthorParams,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAllQuizzesV2 = (
  searchQuizSetReq: BodyType<SearchQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SearchResponseListSearchQuizResV1>(
    {
      url: `/quizsets/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchQuizSetReq,
    },
    options,
  );
};

export const getGetAllQuizzesV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllQuizzesV2>>,
    TError,
    { data: BodyType<SearchQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAllQuizzesV2>>,
  TError,
  { data: BodyType<SearchQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAllQuizzesV2>>,
    { data: BodyType<SearchQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return getAllQuizzesV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAllQuizzesV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuizzesV2>>
>;
export type GetAllQuizzesV2MutationBody = BodyType<SearchQuizSetReq>;
export type GetAllQuizzesV2MutationError = ErrorType<unknown>;

export const useGetAllQuizzesV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllQuizzesV2>>,
    TError,
    { data: BodyType<SearchQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getAllQuizzesV2>>,
  TError,
  { data: BodyType<SearchQuizSetReq> },
  TContext
> => {
  const mutationOptions = getGetAllQuizzesV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlayCount = (
  params: UpdatePlayCountParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyObject>(
    { url: `/quizsets/play-count`, method: 'POST', params },
    options,
  );
};

export const getUpdatePlayCountMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlayCount>>,
    TError,
    { params: UpdatePlayCountParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlayCount>>,
  TError,
  { params: UpdatePlayCountParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlayCount>>,
    { params: UpdatePlayCountParams }
  > = props => {
    const { params } = props ?? {};

    return updatePlayCount(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlayCountMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlayCount>>
>;

export type UpdatePlayCountMutationError = ErrorType<unknown>;

export const useUpdatePlayCount = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlayCount>>,
    TError,
    { params: UpdatePlayCountParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlayCount>>,
  TError,
  { params: UpdatePlayCountParams },
  TContext
> => {
  const mutationOptions = getUpdatePlayCountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const uploadImage = (
  uploadImageBody: BodyType<UploadImageBody>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyUploadImageRes>(
    {
      url: `/quizsets/image`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: uploadImageBody,
    },
    options,
  );
};

export const getUploadImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImage>>,
    TError,
    { data: BodyType<UploadImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadImage>>,
  TError,
  { data: BodyType<UploadImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadImage>>,
    { data: BodyType<UploadImageBody> }
  > = props => {
    const { data } = props ?? {};

    return uploadImage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadImage>>
>;
export type UploadImageMutationBody = BodyType<UploadImageBody>;
export type UploadImageMutationError = ErrorType<unknown>;

export const useUploadImage = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImage>>,
    TError,
    { data: BodyType<UploadImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadImage>>,
  TError,
  { data: BodyType<UploadImageBody> },
  TContext
> => {
  const mutationOptions = getUploadImageMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getQuizSetByEnterCode = (
  quizSetEnterReq: BodyType<QuizSetEnterReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyQuizDetailsRes>(
    {
      url: `/quizsets/enter-code`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: quizSetEnterReq,
    },
    options,
  );
};

export const getGetQuizSetByEnterCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
    TError,
    { data: BodyType<QuizSetEnterReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
  TError,
  { data: BodyType<QuizSetEnterReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
    { data: BodyType<QuizSetEnterReq> }
  > = props => {
    const { data } = props ?? {};

    return getQuizSetByEnterCode(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetQuizSetByEnterCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEnterCode>>
>;
export type GetQuizSetByEnterCodeMutationBody = BodyType<QuizSetEnterReq>;
export type GetQuizSetByEnterCodeMutationError = ErrorType<unknown>;

export const useGetQuizSetByEnterCode = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
    TError,
    { data: BodyType<QuizSetEnterReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
  TError,
  { data: BodyType<QuizSetEnterReq> },
  TContext
> => {
  const mutationOptions = getGetQuizSetByEnterCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createQuizSet = (
  createQuizSetReq: BodyType<CreateQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyQuizSetDetailsRes>(
    {
      url: `/quizsets/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createQuizSetReq,
    },
    options,
  );
};

export const getCreateQuizSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizSet>>,
    TError,
    { data: BodyType<CreateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuizSet>>,
  TError,
  { data: BodyType<CreateQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuizSet>>,
    { data: BodyType<CreateQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return createQuizSet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuizSetMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuizSet>>
>;
export type CreateQuizSetMutationBody = BodyType<CreateQuizSetReq>;
export type CreateQuizSetMutationError = ErrorType<unknown>;

export const useCreateQuizSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizSet>>,
    TError,
    { data: BodyType<CreateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuizSet>>,
  TError,
  { data: BodyType<CreateQuizSetReq> },
  TContext
> => {
  const mutationOptions = getCreateQuizSetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const generateQuizzesByAI = (
  generateQuizzesByAIReq: BodyType<GenerateQuizzesByAIReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyListQuestionDto>(
    {
      url: `/quizsets/ai`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: generateQuizzesByAIReq,
    },
    options,
  );
};

export const getGenerateQuizzesByAIMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateQuizzesByAI>>,
    TError,
    { data: BodyType<GenerateQuizzesByAIReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateQuizzesByAI>>,
  TError,
  { data: BodyType<GenerateQuizzesByAIReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateQuizzesByAI>>,
    { data: BodyType<GenerateQuizzesByAIReq> }
  > = props => {
    const { data } = props ?? {};

    return generateQuizzesByAI(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateQuizzesByAIMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateQuizzesByAI>>
>;
export type GenerateQuizzesByAIMutationBody = BodyType<GenerateQuizzesByAIReq>;
export type GenerateQuizzesByAIMutationError = ErrorType<unknown>;

export const useGenerateQuizzesByAI = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateQuizzesByAI>>,
    TError,
    { data: BodyType<GenerateQuizzesByAIReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateQuizzesByAI>>,
  TError,
  { data: BodyType<GenerateQuizzesByAIReq> },
  TContext
> => {
  const mutationOptions = getGenerateQuizzesByAIMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deprecatedUpdatePlayCount = (
  params: DeprecatedUpdatePlayCountParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyObject>(
    { url: `/quiz/playCount`, method: 'POST', params },
    options,
  );
};

export const getDeprecatedUpdatePlayCountMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deprecatedUpdatePlayCount>>,
    TError,
    { params: DeprecatedUpdatePlayCountParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deprecatedUpdatePlayCount>>,
  TError,
  { params: DeprecatedUpdatePlayCountParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deprecatedUpdatePlayCount>>,
    { params: DeprecatedUpdatePlayCountParams }
  > = props => {
    const { params } = props ?? {};

    return deprecatedUpdatePlayCount(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeprecatedUpdatePlayCountMutationResult = NonNullable<
  Awaited<ReturnType<typeof deprecatedUpdatePlayCount>>
>;

export type DeprecatedUpdatePlayCountMutationError = ErrorType<unknown>;

export const useDeprecatedUpdatePlayCount = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deprecatedUpdatePlayCount>>,
    TError,
    { params: DeprecatedUpdatePlayCountParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deprecatedUpdatePlayCount>>,
  TError,
  { params: DeprecatedUpdatePlayCountParams },
  TContext
> => {
  const mutationOptions = getDeprecatedUpdatePlayCountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const editQuizSet = (
  updateQuizSetReq: BodyType<UpdateQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyQuizSetDetailsRes>(
    {
      url: `/quizsets/edit`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateQuizSetReq,
    },
    options,
  );
};

export const getEditQuizSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizSet>>,
    TError,
    { data: BodyType<UpdateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editQuizSet>>,
  TError,
  { data: BodyType<UpdateQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editQuizSet>>,
    { data: BodyType<UpdateQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return editQuizSet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditQuizSetMutationResult = NonNullable<
  Awaited<ReturnType<typeof editQuizSet>>
>;
export type EditQuizSetMutationBody = BodyType<UpdateQuizSetReq>;
export type EditQuizSetMutationError = ErrorType<unknown>;

export const useEditQuizSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizSet>>,
    TError,
    { data: BodyType<UpdateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editQuizSet>>,
  TError,
  { data: BodyType<UpdateQuizSetReq> },
  TContext
> => {
  const mutationOptions = getEditQuizSetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteQuizSet = (
  deleteQuizSetReq: BodyType<DeleteQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessBodyObject>(
    {
      url: `/quizsets/delete`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: deleteQuizSetReq,
    },
    options,
  );
};

export const getDeleteQuizSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizSet>>,
    TError,
    { data: BodyType<DeleteQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteQuizSet>>,
  TError,
  { data: BodyType<DeleteQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteQuizSet>>,
    { data: BodyType<DeleteQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return deleteQuizSet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteQuizSetMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteQuizSet>>
>;
export type DeleteQuizSetMutationBody = BodyType<DeleteQuizSetReq>;
export type DeleteQuizSetMutationError = ErrorType<unknown>;

export const useDeleteQuizSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizSet>>,
    TError,
    { data: BodyType<DeleteQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteQuizSet>>,
  TError,
  { data: BodyType<DeleteQuizSetReq> },
  TContext
> => {
  const mutationOptions = getDeleteQuizSetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getQuizSetByEntryMap = (
  params: GetQuizSetByEntryMapParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyQuizEntryMapRes>(
    { url: `/quizsets/entry-map`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetByEntryMapQueryKey = (
  params: GetQuizSetByEntryMapParams,
) => {
  return [`/quizsets/entry-map`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetByEntryMapQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetByEntryMapQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>
  > = ({ signal }) => getQuizSetByEntryMap(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetByEntryMapQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEntryMap>>
>;
export type GetQuizSetByEntryMapQueryError = ErrorType<unknown>;

export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetByEntryMapQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetByEntryMapSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetByEntryMapQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>
  > = ({ signal }) => getQuizSetByEntryMap(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetByEntryMapSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEntryMap>>
>;
export type GetQuizSetByEntryMapSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetByEntryMapSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizSetDetails = (
  params: GetQuizSetDetailsParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyQuizSetDetailsRes>(
    { url: `/quizsets/details`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetDetailsQueryKey = (
  params: GetQuizSetDetailsParams,
) => {
  return [`/quizsets/details`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetDetails>>
  > = ({ signal }) => getQuizSetDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetDetails>>
>;
export type GetQuizSetDetailsQueryError = ErrorType<unknown>;

export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetDetailsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetDetailsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetDetails>>
  > = ({ signal }) => getQuizSetDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetDetailsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetDetails>>
>;
export type GetQuizSetDetailsSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetDetailsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizSetBuildInfo = (
  params?: GetQuizSetBuildInfoParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyQuizSetBuildInfoRes>(
    { url: `/quizsets/build-info`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetBuildInfoQueryKey = (
  params?: GetQuizSetBuildInfoParams,
) => {
  return [`/quizsets/build-info`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetBuildInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetBuildInfoQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetBuildInfo>>
  > = ({ signal }) => getQuizSetBuildInfo(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetBuildInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetBuildInfo>>
>;
export type GetQuizSetBuildInfoQueryError = ErrorType<unknown>;

export function useGetQuizSetBuildInfo<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetQuizSetBuildInfoParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetBuildInfo<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetBuildInfo<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetBuildInfo<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetBuildInfoQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetBuildInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetBuildInfoQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetBuildInfo>>
  > = ({ signal }) => getQuizSetBuildInfo(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetBuildInfoSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetBuildInfo>>
>;
export type GetQuizSetBuildInfoSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetBuildInfoSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetQuizSetBuildInfoParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetBuildInfoSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetBuildInfoSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetBuildInfoSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetQuizSetBuildInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetBuildInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetBuildInfoSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const verifyAuthor = (
  params: VerifyAuthorParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyQuizAuthorRes>(
    { url: `/quizsets/author`, method: 'GET', params, signal },
    options,
  );
};

export const getVerifyAuthorQueryKey = (params: VerifyAuthorParams) => {
  return [`/quizsets/author`, ...(params ? [params] : [])] as const;
};

export const getVerifyAuthorQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyAuthorQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyAuthor>>> = ({
    signal,
  }) => verifyAuthor(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof verifyAuthor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyAuthorQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyAuthor>>
>;
export type VerifyAuthorQueryError = ErrorType<unknown>;

export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyAuthor>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyAuthor>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyAuthorQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getVerifyAuthorSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyAuthorQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyAuthor>>> = ({
    signal,
  }) => verifyAuthor(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof verifyAuthor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyAuthorSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyAuthor>>
>;
export type VerifyAuthorSuspenseQueryError = ErrorType<unknown>;

export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyAuthorSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deprecatedGetQuizSetDetails = (
  params: DeprecatedGetQuizSetDetailsParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessBodyQuizSetDetailsRes>(
    { url: `/quiz/details`, method: 'GET', params, signal },
    options,
  );
};

export const getDeprecatedGetQuizSetDetailsQueryKey = (
  params: DeprecatedGetQuizSetDetailsParams,
) => {
  return [`/quiz/details`, ...(params ? [params] : [])] as const;
};

export const getDeprecatedGetQuizSetDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDeprecatedGetQuizSetDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>
  > = ({ signal }) =>
    deprecatedGetQuizSetDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DeprecatedGetQuizSetDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>
>;
export type DeprecatedGetQuizSetDetailsQueryError = ErrorType<unknown>;

export function useDeprecatedGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDeprecatedGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDeprecatedGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDeprecatedGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDeprecatedGetQuizSetDetailsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDeprecatedGetQuizSetDetailsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDeprecatedGetQuizSetDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>
  > = ({ signal }) =>
    deprecatedGetQuizSetDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DeprecatedGetQuizSetDetailsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>
>;
export type DeprecatedGetQuizSetDetailsSuspenseQueryError = ErrorType<unknown>;

export function useDeprecatedGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDeprecatedGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDeprecatedGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDeprecatedGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: DeprecatedGetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof deprecatedGetQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDeprecatedGetQuizSetDetailsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
