import { Fragment } from 'react';
import { DialogBackdrop, PopoverOverlayProps, TransitionChild } from '@headlessui/react';
import { cn } from '@zep/utils';
export const DialogOverlay = ({
  className,
  ...rest
}: PopoverOverlayProps<'div'>) => {
  return <TransitionChild as={Fragment} enter={'transition-all duration-200 ease-in'} enterFrom={'opacity-0'} enterTo={'opacity-100'} leave={'transition-all duration-200 ease-out'} leaveFrom={'opacity-100'} leaveTo={'opacity-0'} data-sentry-element="TransitionChild" data-sentry-component="DialogOverlay" data-sentry-source-file="DialogOverlay.tsx">
      <DialogBackdrop {...rest} className={cn('fixed z-0 inset-0 bg-[rgba(28,27,31,0.24)]', className)} data-sentry-element="DialogBackdrop" data-sentry-source-file="DialogOverlay.tsx" />
    </TransitionChild>;
};